import {Box, MenuItem, TextField, Paper, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AdminDetailListItem from '../AdminMemberProfile/components/AdminDetailListItem';
import {setCurrentMemberData} from 'Redux/Slices/selectedMember';
import ResetPasswordAndWebenable from './ResetPasswordAndWebenable';
import {searchSRByTypeRecent} from 'Redux/Slices/RecentSRList';
import {searchSRByTypePayload} from 'Utils/helpers/SRPayloadGenerator';
import {searchSRByTypeArchived} from 'Redux/Slices/ArchivedSRList';
import {searchSRByTypeCashback} from 'Redux/Slices/CashbackSRList';
import Loading from 'Components/Loading';

const leftPannelData = [
  {
    key: 'memberNumber',
    value: 'Member#',
  },
  {
    key: 'externalRefId',
    value: 'External Ref#',
  },
  {
    key: 'status',
    value: 'Status',
  },
  {
    key: 'salutation',
    value: 'Salutation',
  },
  {
    key: 'namePrefix',
    value: 'Title',
  },
  {
    key: 'firstName',
    value: 'First Name',
  },
  {
    key: 'lastName',
    value: 'Last Name',
  },
  {
    key: 'clientEmail',
    value: 'Email Address',
  },

  {
    key: 'dateOfBirth',
    value: 'D.O.B',
  },
  {
    key: 'age',
    value: 'Age',
  },
  {
    key: 'addressLine1',
    value: 'Address 1',
  },
  {
    key: 'addressLine2',
    value: 'Address 2',
  },
  {
    key: 'postalCode',
    value: 'Post Code',
  },
  {
    key: 'country',
    value: 'Country',
  },
  {
    key: 'packageName',
    value: 'Package',
  },
  {
    key: 'joinDate',
    value: 'Join Date',
  },
  // {
  //   key: 'isWebEnabled',
  //   value: 'Web Enabled',
  //   type: 'checkbox',
  // },
  {
    key: 'webEnabledDate',
    value: 'Onboarding Date',
  },
];

const MemberDetailsPanel = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const member = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const focusedMember = [
    {
      name:
        member?.focusedMemberDetails['firstName'] + ' ' + member.focusedMemberDetails['lastName'],
      memberNumber: member?.focusedMemberDetails?.memberId,
    },
  ];
  const jointMembers = (member?.jointMemberDetails || []).map((obj) => ({
    name: `${obj.firstName} ${obj.lastName}`,
    memberNumber: obj.memberId,
  }));

  const avaiableMembers = [member.focusedMemberDetails, ...(member?.jointMemberDetails || [])];
  const members1 = [...focusedMember, ...jointMembers];
  const members = members1.map((item) => item['name']);
  const [selectedMember, setSelectedMember] = useState(members[0]);
  const loginDetails = useSelector((state: any) => state?.login);

  const handleChange = async (event) => {
    setLoading(true);
    const selectedMemeberObj: any = members1.filter((a) => a.name === event.target.value);

    const finalMember = avaiableMembers.filter(
      (a) => a.memberId === selectedMemeberObj[0].memberNumber,
    );
    const updatedMemberData = {
      ...member,
      currentMemberDetails: finalMember[0],
    };

    setLoading(false);
    Promise.all([
      dispatch(setCurrentMemberData(finalMember[0])),
      dispatch(
        searchSRByTypeRecent(searchSRByTypePayload(updatedMemberData, 'recent', loginDetails)),
      ),
      dispatch(
        searchSRByTypeArchived(searchSRByTypePayload(updatedMemberData, 'archive', loginDetails)),
      ),
      dispatch(
        searchSRByTypeCashback(searchSRByTypePayload(updatedMemberData, 'cashback', loginDetails)),
      ),
    ]);

    setSelectedMember(event.target.value);
    // setSelectedOption(event.target); // Update the selected option
  };

  if (loading) return <Loading isOpen={true} />;
  return (
    <div
      style={{
        width: '24vw',
        margin: '0.75rem 10px',
      }}>
      <Paper style={{padding: '0.5rem'}}>
        <ResetPasswordAndWebenable />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}>
          <Typography variant="body1" component="div" sx={styles.labelContainer}>
            {'Member'}
          </Typography>

          <TextField
            select
            size="small"
            style={{width: '212px', marginLeft: '9px'}}
            value={selectedMember}
            name="Members"
            onChange={handleChange}>
            {members.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {leftPannelData.map((item, index) => {
          if (item.key in member?.currentMemberDetails) {
            return (
              <Box key={item.key} sx={{marginRight: '1rem'}}>
                <AdminDetailListItem
                  label={item.value}
                  value={member?.currentMemberDetails?.[item?.key] ?? ''}
                  // type={item?.type}
                />
              </Box>
            );
          } else {
            return (
              <Box key={item.key} sx={{marginRight: '1rem'}}>
                <AdminDetailListItem
                  label={item.value}
                  value={member?.[item?.key] ?? ''}
                  // type={item?.type}
                />
              </Box>
            );
          }
        })}
      </Paper>
    </div>
  );
};

export default MemberDetailsPanel;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  labelContainer: {
    width: '100px',
  },
};
