import React, {useEffect, useState} from 'react';
import SRListView from './components/SRListView';
import {Button, Typography} from '@mui/material';
import globalSpacing from 'Theme/Spacing';
import SRDetailViewDialog from 'Pages/Tabs/ServiceRequests/components/SRDetailViewDialog';
import {useDispatch, useSelector} from 'react-redux';
import {resetSelectedSRData} from 'Redux/Slices/selectedSR';
import {useLocation} from 'react-router-dom';
import {CustomTextInput, Loading} from 'Components';
import {searchSRByTypeCashback, selectAllCashbackSRList} from 'Redux/Slices/CashbackSRList';
import {searchSRByTypePayload} from 'Utils/helpers/SRPayloadGenerator';
import {searchSRByTypeRecent, selectAllRecentSRList} from 'Redux/Slices/RecentSRList';
import {searchSRByTypeArchived, selectAllArchivedSRList} from 'Redux/Slices/ArchivedSRList';
import {isUpdateSRList, updateFilterType} from 'Redux/Slices/SRListFilter';

const TabServiceRequests = () => {
  const [openSRDetailViewDialogDialog, isOenSRDetailViewDialogDialog] = useState(false);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const state = location?.state;
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  const recentSRList: any = useSelector(selectAllRecentSRList);
  const cashbackSRList: any = useSelector(selectAllCashbackSRList);
  const archivedSRList: any = useSelector(selectAllArchivedSRList);
  const isRefreshSRList = useSelector((state: any) => state?.SRListFilter?.isRefreshSRList);
  const selectedFilterType = useSelector((state: any) => state?.SRListFilter?.filterType);
  const loginDetails = useSelector((state: any) => state?.login);

  const [SRListData, setSRListData] = useState<any[]>(recentSRList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state?.isSRDialogView) {
      handleSRRowClick();
      state.isSRDialogView = false;
    }
  }, [state]);

  useEffect(() => {
    switch (filterOption) {
      case 'Cashback SRs': {
        setSRListData(cashbackSRList);
        break;
      }
      case 'Archived SRs': {
        setSRListData(archivedSRList);
        break;
      }
      case 'Recent SRs': {
        setSRListData(recentSRList);
        break;
      }
      default: {
        let srData = [...archivedSRList, ...recentSRList];
        setSRListData(
          srData.filter((sr) => {
            return sr.type.toLowerCase().includes(filterOption.toLowerCase());
          }),
        );
        break;
      }
    }
  }, [recentSRList, cashbackSRList, archivedSRList, isRefreshSRList]);

  useEffect(() => {
    if (isRefreshSRList) {
      loadSRList(filterOption);
      dispatch(
        isUpdateSRList({
          isRefreshSRList: false,
        }),
      );
    }
  }, [isRefreshSRList]);

  // useEffect(() => {
  //   loadSRList(filterOption);
  // }, [isRefreshSRList]);

  const handleSRRowClick = () => {
    isOenSRDetailViewDialogDialog(true);
  };

  const handleNewSRClick = async () => {
    await dispatch(resetSelectedSRData());
    isOenSRDetailViewDialogDialog(true);
  };
  const options = [
    'Recent SRs',
    'Archived SRs',
    'Cashback SRs',
    'Complaint',
    'Membership Services',
    'Welcome Call',
    'Cinema Query',
    'Maintain Member Details',
    'Web Account Details Reset',
    'Re-fulfill Gift Card',
    'CLO Enquiry',
    'Cashback Enquiry',
    'Coupon Enquiry',
    'General Enquiry',
    'Gifting - Agent',
  ];

  const [filterOption, setFilterOption] = useState(selectedFilterType || options[0]);

  const loadSRList = async (filterOption) => {
    switch (filterOption) {
      case 'Cashback SRs': {
        setLoading(true);
        let res = await dispatch(
          searchSRByTypeCashback(searchSRByTypePayload(selectedMember, 'cashback', loginDetails)),
        );
        setLoading(false);
        setSRListData(
          res?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList,
        );
        break;
      }
      case 'Archived SRs': {
        setLoading(true);
        let res = await dispatch(
          searchSRByTypeArchived(searchSRByTypePayload(selectedMember, 'archive', loginDetails)),
        );
        setLoading(false);
        setSRListData(
          res?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList,
        );
        break;
      }
      case 'Recent SRs': {
        setLoading(true);
        let res = await dispatch(
          searchSRByTypeRecent(searchSRByTypePayload(selectedMember, 'recent', loginDetails)),
        );
        setLoading(false);
        setSRListData(
          res?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList,
        );
        break;
      }
      default: {
        setLoading(true);
        let res = await dispatch(
          searchSRByTypeArchived(searchSRByTypePayload(selectedMember, 'archive', loginDetails)),
        );
        setLoading(false);
        let archiveData = res?.payload?.data?.getServiceRequestsByType
          ? res?.payload?.data?.getServiceRequestsByType?.searchServiceRequestsOutputDtoList
          : [];

        let srData = [...archiveData, ...recentSRList];
        setSRListData(
          srData.filter((sr) => {
            return sr.type.toLowerCase().includes(filterOption.toLowerCase());
          }),
        );
      }
    }
  };

  const handleChange = (e) => {
    setFilterOption(e.target.value);
    dispatch(updateFilterType(e.target.value));
    loadSRList(e.target.value);
  };

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <CustomTextInput
          inline
          label="Filter By"
          name="filter"
          filterMenu={options}
          value={filterOption}
          onChange={handleChange}
          size="small"
        />
        <Button
          onClick={handleNewSRClick}
          variant="contained"
          sx={{
            paddingY: globalSpacing.xxs,
            marginY: globalSpacing.sm,
          }}>
          <Typography variant="body2" sx={{textTransform: 'none'}}>
            {'New SR'}
          </Typography>
        </Button>
      </div>
      <SRDetailViewDialog
        openSRDetailViewDialogDialog={openSRDetailViewDialogDialog}
        isOenSRDetailViewDialogDialog={isOenSRDetailViewDialogDialog}
      />
      {loading ? (
        <Loading isOpen={true} />
      ) : (
        <SRListView handleSRRowClick={handleSRRowClick} SRListData={SRListData} />
      )}
    </div>
  );
};

export default TabServiceRequests;
