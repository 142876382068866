import React, {useEffect, Fragment, useState} from 'react';
import {Box, Paper, Typography, Divider, Grid, Button} from '@mui/material';
import styles from './styles';
import {useDispatch, useSelector} from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import {
  gqlSRSearchByClaimId,
  gqlGetPaymentDetails,
  gqlCashbackByClaimId,
} from 'GQL/MethodsBFF/ServiceRequest';
import {DATE_FORMAT} from 'Utils/constants/DateFormat';
import {format} from 'date-fns';
import globalSpacing from 'Theme/Spacing';
import {resetSelectedSRData} from 'Redux/Slices/selectedSR';
import SRDetailViewDialog from '../ServiceRequests/components/SRDetailViewDialog';
import SRListView from '../ServiceRequests/components/SRListView';
import {BackButtonWText} from 'Components';
import {getCurrency} from 'Utils/helpers/SRDetailViewHelper';
import TableView from 'Components/TableView';
import {getPaymentData} from '../ServiceRequests/components/Payment/PaymentListView/PaymentListGridDataSource';
import {SortOrder} from 'Utils/enums';
import parse from 'html-react-parser';
import TnCDialog from './TnCDialog';
import {dateConversionIsoToLocal, isValidISO8601} from 'Utils/helpers/DateValidation';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';

type TransactionDetailsProps = {
  selectedTransaction: any;
  selectedOffer: any;
  toggleView?: any;
};

const allowedOfferTypes = [
  'instantwin',
  'reward',
  'prizedraw',
  'giftcard',
  'voucher',
  'cashback',
  'travel',
  'cardlinked',
];

const otherOfferTypes = ['event', 'cinema', 'cinema_rental'];

const fieldsData = {
  bookingDetails: [
    {label: 'Channel', key: 'channel'},
    //{label: 'First Name', key: 'data.firstName'},
    //{label: 'Last Name', key: 'data.lastName'},
    //{label: 'Email', key: 'data.emailId'},
    // {label: 'Phone No', key: 'data.phoneNumber'},
    {label: 'Booking Status', key: 'status'},
    {label: 'Vendor Name', key: 'data.claimDetails.merchantName'},
    {label: 'Chain Name', key: 'data.contentProvider'},
    {label: 'Offer Type', key: 'offerType'},
    {label: 'Ticket Delivery', key: 'data.ticketDelivery', default: 'Online'},
    {
      label: 'Cashback Applicable',
      key: 'isCashbackApplicable',
      transform: (value: boolean) => (value ? 'Yes' : 'No'),
    },
    {
      label: 'Transaction Id',
      key: 'id',
    },
  ],
  travelBookingDetails: [
    {label: 'Traveler Name', key: 'data.claimDetails.travelerName'},
    {label: 'Invoice No', key: 'data.claimDetails.invoiceNo'},
    {label: 'confirmationNo', key: 'data.claimDetails.confirmationNo'},
    {label: 'Travel Date', key: 'data.claimDetails.startDate'},
    {label: 'Cancel Date', key: 'data.claimDetails.cancelDate'},
    {label: 'description', key: 'data.claimDetails.description'},
  ],
  eventDetails: [
    {label: 'Booking Reference', key: 'data.claimDetails.orderData.data.orderId'},
    {
      label: 'Event Name',
      key: (transaction) =>
        transaction?.data?.claimDetails?.eventName?.trim()
          ? transaction.data.claimDetails.eventName
          : transaction?.data?.claimDetails?.orderData?.data?.orderId,
    },
    {label: 'Event Category', key: 'data.claimDetails.category'},
    {label: 'Venue Name', key: 'data.claimDetails.venueDetails.venueName'},
    {label: 'Booking Date', key: 'claimDateTime'},
    {label: 'Event Date', key: 'data.claimDetails.slotTime'},
    //{ label: 'Seat Details', key: 'data.claimDetails.seatDetails' },
    //{ label: 'Postage Fee', key: 'data.postageFee' },
    {label: 'Booking Fee', key: 'data.claimDetails.orderData.data.transactionFees'},
    {label: 'Actual Total Price', key: 'data.claimDetails.orderData.data.totalAmount'},
    //{label: 'Cashback %', key: 'data.cashbackPercentage'},
    //{label: 'Cashback Amount', key: 'data.cashbackAmount'},
    //{label: 'Total Cashback', key: 'data.totalCashback'},
    //{label: 'Price After Cashback', key: 'data.priceAfterCashback'},
  ],
  cinemaEventDetails: [
    {label: 'Booking Reference', key: 'data.claimDetails.orderData.data.orderId'},
    {label: 'Booking Date', key: 'claimDateTime'},
    {label: 'Total amount', key: 'data.claimDetails.orderData.data.totalAmount'},
    //{label: 'Currency', key: 'data.currency'},
    {label: 'No of Vouchers', key: 'data.claimDetails.orderData.data.totalTickets'},
  ],
  paymentDetails: [
    {label: 'Transaction ID', key: 'data.claimDetails.threeDsResponse.billId'},
    {label: 'Amount', key: 'data.claimDetails.orderData.data.totalAmount'},
    {label: 'Status', key: 'data.claimDetails.threeDsResponse.transactionStatus'},
    {label: 'Flow Type', key: 'data.paymentFlowType'},
    {label: 'Created By', key: 'data.createdBy'},
    {label: 'Create Date', key: 'data.createDate'},
    {label: 'Last Processed Date', key: 'data.lastProcessedDate'},
  ],
};

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  selectedTransaction,
  selectedOffer,
  toggleView,
}) => {
  //console.log(selectedOffer, selectedTransaction);
  // Always call Hooks at the top level
  const loginDetails = useSelector((state: any) => state?.login);
  //let memberDetails = useSelector((state: any) => state?.selectedMember?.selectedMember);
  //let currentMemberDetails = memberDetails?.currentMemberDetails;
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);

  const dispatch = useDispatch();
  const [openSRDetailViewDialogDialog, isOenSRDetailViewDialogDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [serviceRequests, setServiceRequests] = React.useState<any[]>([]); // State to hold service requests
  const [paymentDetailsRequests, setPaymentDetailsRequests] = React.useState<any[]>([]); // State to hold service requests
  const {selectedMember} = useSelector((state: any) => state?.selectedMember);
  type CashbackDetails = {
    cashbackPercentage: number;
    cashbackAmount: number;
    totalCashback: number;
    priceAfterCashback: number;
  };
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const toggleTnDDialog = () => {
    setIsDialogVisible(!isDialogVisible);
  };

  const [cashbackDetailsRequests, setCashbackDetailsRequests] =
    React.useState<CashbackDetails | null>(null); // State to hold service requests

  const fetchSrByClaimId = async () => {
    if (!selectedTransaction) return;

    setLoading(true);
    setError(null); // Reset error state

    const headers = {
      'tenant-id': loginDetails?.tenantID,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };

    const payload = {
      tenantId: loginDetails?.tenantID,
      claimId: selectedTransaction?.id,
      pageNumber: 0,
      pageSize: 20,
    };

    try {
      const response = await gqlSRSearchByClaimId(payload, headers);
      //console.log(response);
      const serviceRequests = response?.data?.getServiceRequestByClaimId[0]?.responseList || [];
      setServiceRequests(serviceRequests); // Update state with service request data

      if (serviceRequests.length > 0) {
        const srId = serviceRequests[0]?.id || null;
        console.log('First Service Request ID:', srId); // Debugging log
        // Call the fetchPaymentDetails function with the srId or null
        await fetchPaymentDetailsByBillingId(srId);
      } else {
        const srId = serviceRequests[0]?.id || null;
        await fetchPaymentDetailsByBillingId(srId);
        console.log('No service requests found.'); // Debugging log
      }
    } catch (error) {
      setError('Error fetching transaction data.');
      console.error('Error fetching transaction data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentDetailsByBillingId = async (srreqId) => {
    if (!selectedTransaction) return;

    setLoading(true);
    setError(null); // Reset error state

    const headers = {
      'tenant-id': loginDetails?.tenantID,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };

    const payload = {
      /*srId: selectedTransaction?.data?.claimDetails?.threeDsResponse?.billId
        ? null
        : selectedTransaction?.data?.srId
        ? selectedTransaction?.data?.srId
        : null,*/
      //srId: '1143790743',
      srId: srreqId || null, // Pass srId if it exists; otherwise, null
      memberId: null,
      //id: selectedTransaction?.data?.claimDetails?.threeDsResponse?.billId || null,
      id: srreqId
        ? null // If srreqId exists, id is null
        : selectedTransaction?.data?.claimDetails?.threeDsResponse?.billId || null,
      claimId: selectedTransaction?.id.toString(),
    };

    try {
      const response = await gqlGetPaymentDetails(payload, headers);
      //console.log(response);
      setPaymentDetailsRequests(response?.data?.getPaymentDetails[0]?.paymentDetails || []); // Update state with response data
      //console.log(paymentDetailsRequests);
    } catch (error) {
      setError('Error fetching payments data.');
      console.error('Error fetching transaction data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch cashback details function
  const fetchCashbackDetails = async () => {
    if (!selectedTransaction) return;

    setLoading(true);
    setError(null); // Reset error state

    const headers = {
      'tenant-id': loginDetails?.tenantID,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };

    const payload = {
      //tenantId: loginDetails?.tenantID,
      claimId: selectedTransaction?.id,
      //claimId: 251525,
    };

    try {
      const response = await gqlCashbackByClaimId(payload, headers);
      ///console.log(response);
      const cashbackData = response?.data?.cashbackByClaimId;
      setCashbackDetailsRequests(cashbackData || null); // Update state with response data

      //console.log(response?.data?.cashbackByClaimId);
    } catch (error) {
      setError('Error fetching transaction data.');
      console.error('Error fetching transaction data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Call useEffect without conditions
  useEffect(() => {
    //if (selectedTransaction) {
    fetchSrByClaimId();
    //fetchPaymentDetailsByBillingId();
    if (selectedTransaction?.offerType?.toLowerCase()?.trim() === 'event') {
      fetchCashbackDetails();
    }
    //}
  }, [openSRDetailViewDialogDialog]);

  const renderCashbackDetails = () => {
    if (!cashbackDetailsRequests) {
      return (
        <Grid item xs={12}>
          <Typography variant="body2" sx={{color: 'gray', textAlign: 'center'}}>
            No Cashback details available.
          </Typography>
        </Grid>
      );
    }

    const {cashbackPercentage, cashbackAmount, totalCashback, priceAfterCashback} =
      cashbackDetailsRequests;

    return (
      <Grid container spacing={3}>
        {/* Individual detail items */}
        <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
          <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
            <Typography
              variant="body2"
              sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
              Cashback %:
            </Typography>
            <Typography
              variant="body2"
              sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
              {cashbackPercentage ?? 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
          <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
            <Typography
              variant="body2"
              sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
              Cashback Amount:
            </Typography>
            <Typography
              variant="body2"
              sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
              {cashbackAmount ?? 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
          <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
            <Typography
              variant="body2"
              sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
              Total Cashback:
            </Typography>
            <Typography
              variant="body2"
              sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
              {totalCashback ?? 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
          <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
            <Typography
              variant="body2"
              sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
              Price After Cashback:
            </Typography>
            <Typography
              variant="body2"
              sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
              {priceAfterCashback ?? 'N/A'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  // const renderServiceRequestTable = () => (
  //   <Box sx={{marginTop: 3}}>
  //     <Typography
  //       variant="h6"
  //       sx={{
  //         margin: '10px 10px',
  //         fontWeight: 'bold',
  //         textAlign: 'left',
  //         fontFamily: 'inherit',
  //       }}>
  //       Service Request Details
  //     </Typography>
  //     <Table>
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>ID</TableCell>
  //           <TableCell>Created On</TableCell>
  //           <TableCell>Created By</TableCell>
  //           <TableCell>SR Type</TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {serviceRequests.length > 0 ? (
  //           serviceRequests.map((sr: any) => (
  //             <TableRow key={sr.id}>
  //               <TableCell>{sr.id}</TableCell>
  //               <TableCell>
  //                 {format(new Date(sr.audit?.createdOn), DATE_FORMAT.dateFormat) || 'N/A'}
  //               </TableCell>
  //               <TableCell>{sr.audit?.createdBy || 'N/A'}</TableCell>
  //               <TableCell>{sr.type || 'N/A'}</TableCell>
  //             </TableRow>
  //           ))
  //         ) : (
  //           <TableRow>
  //             <TableCell colSpan={4} sx={{textAlign: 'center'}}>
  //               No service requests available.
  //             </TableCell>
  //           </TableRow>
  //         )}
  //       </TableBody>
  //     </Table>
  //   </Box>
  // );

  // const renderPaymentDetails = () => (
  //   <Grid container spacing={3}>
  //     {paymentDetailsRequests.length > 0 ? (
  //       paymentDetailsRequests.map((detail: any, index: number) => (
  //         <React.Fragment key={index}>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Transaction ID:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {detail.requestId || 'N/A'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Amount:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {detail.amount || 'N/A'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Status:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {detail.status == 'AUTHORISED' ? 'Success' : 'Fail'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Flow Type:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {detail.flowType || 'N/A'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Created By:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {detail.createdBy || 'N/A'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Create Date:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {format(new Date(detail.createdDate), DATE_FORMAT.dateFormat) || 'N/A'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //           <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6}>
  //             <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
  //               <Typography
  //                 variant="body2"
  //                 sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
  //                 Last Processed Date:
  //               </Typography>
  //               <Typography
  //                 variant="body2"
  //                 sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
  //                 {format(new Date(detail.lastProcessedDate), DATE_FORMAT.dateFormat) || 'N/A'}
  //               </Typography>
  //             </Box>
  //           </Grid>
  //         </React.Fragment>
  //       ))
  //     ) : (
  //       <Grid item xs={12}>
  //         <Typography variant="body2" sx={{color: 'gray', textAlign: 'center'}}>
  //           No payment details available.
  //         </Typography>
  //       </Grid>
  //     )}
  //   </Grid>
  // );

  if (!selectedTransaction) {
    return (
      <Typography variant="body2" sx={{padding: 2}}>
        Transaction details are not available.
      </Typography>
    );
  }

  const getValue = (obj: any, path: string, transform?: (value: any) => any) => {
    const value = path
      .split('.')
      .reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : '-'), obj);
    if (value === '-') return '-';
    if (value == null || value === '') return 'N/A';
    return transform ? transform(value) : value;
  };

  /*const renderFields = (fields: any[], source: any) =>
    fields.map(
      ({
        label,
        key,
        default: defaultValue,
        transform,
      }: {
        label: string;
        key: string;
        default?: string;
        transform?: (value: any) => any;
      }) => (
        <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={6} key={label}>
          <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
            <Typography
              variant="body2"
              sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
              {label}:
            </Typography>
            <Typography
              variant="body2"
              sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
              {defaultValue !== undefined &&
              (getValue(source, key) === 'N/A' || getValue(source, key) === 'NEED INFO')
                ? defaultValue
                : getValue(source, key, transform)}
            </Typography>
          </Box>
        </Grid>
      ),
    );*/
  const renderFields = (fields: any[], source: any) =>
    fields.map(
      ({
        label,
        key,
        default: defaultValue,
        transform,
      }: {
        label: string;
        key: string | ((source: any) => any); // Allow key to be a string or a function
        default?: string;
        transform?: (value: any) => any;
      }) => {
        // Resolve the value based on whether key is a string or function
        let value =
          typeof key === 'function'
            ? key(source) // Execute function if key is callable
            : getValue(source, key, transform); // Otherwise, fetch value normally

        // Check if it's the Travel Date (startDate) and needs formatting
        if (
          key === 'data.claimDetails.startDate' ||
          (key === 'data.claimDetails.cancelDate' && isValidISO8601(value))
        ) {
          value = dateConversionIsoToLocal(value, FORMAT_TYPE.DATE_TIME, '', tenantConfig?.locale);
        }
        return (
          <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4} key={label}>
            <Box sx={{borderBottom: '1px solid gray', paddingBottom: '8px', marginBottom: '8px'}}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                {label}:
              </Typography>
              <Typography
                variant="body2"
                sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
                {value || defaultValue || '-'}
              </Typography>
            </Box>
          </Grid>
        );
      },
    );

  /*const renderEventTicketDetails = (seatInfo: any[]) =>
    seatInfo.map((ticket, index) => (
      <React.Fragment key={index}>
        <Typography
          variant="subtitle1"
          sx={{
            margin: '5px 5px',
            fontWeight: 'bold',
            marginTop: 2,
            textAlign: 'center',
            fontFamily: 'inherit',
          }}>
          Ticket {index + 1}
        </Typography>
        <Box sx={{padding: 2, marginBottom: 2, backgroundColor: '#f9f9f9', borderRadius: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Product Name:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {getValue(ticket, 'ticketOptionName')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Number of Tickets:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {1}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Status:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {'Confirmed'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{marginTop: 1}}>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Price/Ticket:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {getValue(ticket, 'salePriceBuyNow')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Booking Fee:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {getValue(ticket, 'salePriceFees')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Total Line Price:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {getValue(ticket, 'salePriceBuyNow')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{my: 1}} />
      </React.Fragment>
    ));*/
  const renderEventTicketDetails = (seatInfo: any[]) =>
    seatInfo.map((ticket, index) => {
      const ticketName = getValue(ticket, 'ticketOptionName') || 'N/A';
      const salePriceBuyNow = parseFloat(getValue(ticket, 'salePriceBuyNow') || '0');
      const salePriceFees = parseFloat(getValue(ticket, 'salePriceFees') || '0');
      const numTickets = 1; // Replace with dynamic value if available.
      const totalLinePrice = salePriceBuyNow + salePriceFees;

      return (
        <React.Fragment key={index}>
          <Typography
            variant="subtitle1"
            sx={{
              margin: '5px 5px',
              fontWeight: 'bold',
              marginTop: 2,
              textAlign: 'center',
              fontFamily: 'inherit',
            }}>
            Ticket {index + 1}
          </Typography>
          <Box sx={{padding: 2, marginBottom: 2, backgroundColor: '#f9f9f9', borderRadius: 1}}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                  Product Name:
                </Typography>
                <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                  {ticketName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                  Number of Tickets:
                </Typography>
                <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                  {numTickets}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                  Status:
                </Typography>
                <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                  {'Confirmed'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: 1}}>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                  Price/Ticket:
                </Typography>
                <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                  {salePriceBuyNow.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                  Booking Fee:
                </Typography>
                <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                  {salePriceFees.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                  Total Line Price:
                </Typography>
                <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                  {totalLinePrice.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{my: 1}} />
        </React.Fragment>
      );
    });

  /* const renderCinemaTicketDetails = (seatInfo: any[]) =>
    seatInfo.map((ticket, index) => (
      <React.Fragment key={index}>
        <Typography
          variant="subtitle1"
          sx={{
            margin: '5px 5px',
            fontWeight: 'bold',
            marginTop: 2,
            textAlign: 'center',
            fontFamily: 'inherit',
          }}>
          Ticket {index + 1}
        </Typography>
        <Box sx={{padding: 2, marginBottom: 2, backgroundColor: '#f9f9f9', borderRadius: 1}}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Description:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {getValue(ticket, 'ticketOptionName')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Code:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {ticket?.voucherDetails && ticket?.voucherDetails.length > 0
                  ? ticket.voucherDetails[0].code
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{marginTop: 1}}>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Price/Ticket:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {getValue(ticket, 'salePriceBuyNow')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                Expiry Date:
              </Typography>
              <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                {ticket?.voucherDetails?.length > 0 && ticket.voucherDetails[0].expires
                  ? format(new Date(ticket.voucherDetails[0].expires), DATE_FORMAT.dateFormat)
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{my: 1}} />
      </React.Fragment>
    ));*/
  const renderCinemaTicketDetails = (seatInfo: any[]) =>
    seatInfo.map((ticket, index) => (
      <React.Fragment key={index}>
        {ticket?.voucherDetails?.length > 0 ? (
          ticket.voucherDetails.map((voucher: any, voucherIndex: number) => (
            <Box
              key={voucherIndex}
              sx={{padding: 2, marginBottom: 2, backgroundColor: '#f9f9f9', borderRadius: 1}}>
              <Typography
                variant="subtitle1"
                sx={{
                  margin: '5px 5px',
                  fontWeight: 'bold',
                  marginTop: 2,
                  textAlign: 'center',
                  fontFamily: 'inherit',
                }}>
                Ticket {voucherIndex + 1}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                    Description:
                  </Typography>
                  <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                    {ticket.ticketOptionName || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                    Ticket Price:
                  </Typography>
                  <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                    {ticket.amount || '-'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{marginTop: 1}}>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                    Expiry Date:
                  </Typography>
                  <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                    {voucher.expires
                      ? format(new Date(voucher.expires), DATE_FORMAT.dateFormat)
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                    E-Code:
                  </Typography>
                  <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                    {voucher.code || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Typography variant="body2" sx={{marginTop: 2, fontFamily: 'inherit'}}>
            No Vouchers Available
          </Typography>
        )}
        <Divider sx={{my: 1}} />
      </React.Fragment>
    ));

  const handleNewSRClick = async () => {
    await dispatch(resetSelectedSRData());
    isOenSRDetailViewDialogDialog(true);
  };
  const handleSRRowClick = () => {
    isOenSRDetailViewDialogDialog(true);
  };
  //get Claim count and claim limit
  const getAdditionalDetailValue = (details, key) =>
    details?.find((detail) => detail.key === key)?.value || '-';
  //get channel of redemotion
  const getRedemptionMethod = (details) => (details?.length ? details[0]?.method || 'NA' : 'NA');
  //get Status text based on Text
  const getStatusText = (status) => {
    if (!status) return '-';

    const formattedStatus = status.toLowerCase().trim();

    if (formattedStatus === 'winner') return 'Winner';
    if (formattedStatus === 'loser') return "We're Sorry";

    return formattedStatus; // Default to original status if it's neither "winner" nor "loser"
  };

  return (
    <>
      {allowedOfferTypes.includes(selectedTransaction?.offerType?.toLowerCase()?.trim()) && (
        <Paper sx={{padding: globalSpacing.sm}}>
          <Fragment>
            <Box sx={styles.topRow}>
              <BackButtonWText text={'Back to list'} callback={toggleView} />
              <Typography variant="body1">
                Type:{' '}
                <span style={{fontFamily: 'semi-bold'}}>
                  {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'instantwin'
                    ? 'INSTANT_WIN'
                    : selectedTransaction?.offerType?.toUpperCase()}
                </span>
              </Typography>
            </Box>
            <Typography variant="h2" py={globalSpacing.s}>
              {selectedOffer?.titleShort}
            </Typography>
            <Typography variant="body1">
              {selectedOffer?.description ? parse(selectedOffer?.description) : ''}
            </Typography>
            <Button
              onClick={() => toggleTnDDialog()}
              sx={{
                minHeight: '0rem',
                minWidth: '0rem',
                paddingX: '0rem',
                paddingY: globalSpacing.xs,
                marginY: globalSpacing.sm,
              }}>
              <Typography
                variant="body2"
                color="primary"
                sx={{textDecoration: 'underline', textTransform: 'none'}}>
                {'Offers Terms and Conditions'}
              </Typography>
            </Button>
            <Box sx={{padding: 3}}>
              <Grid container spacing={3}>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box>
                    <Typography sx={{marginBottom: '4%'}} variant="subtitle1">
                      {'Fields'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{padding: 3}}>
              <Grid container spacing={3}>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Claim Date:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {isValidISO8601(selectedTransaction?.claimDateTime)
                        ? dateConversionIsoToLocal(
                            selectedTransaction?.claimDateTime,
                            FORMAT_TYPE?.DATE_TIME,
                            '',
                            tenantConfig?.locale,
                          )
                        : selectedTransaction?.claimDateTime || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Expiry Date:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {isValidISO8601(selectedTransaction?.expiryDateTime)
                        ? dateConversionIsoToLocal(
                            selectedTransaction?.expiryDateTime,
                            FORMAT_TYPE?.DATE_TIME,
                            '',
                            tenantConfig?.locale,
                          )
                        : selectedTransaction?.expiryDateTime || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Offer Start Date:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {isValidISO8601(selectedOffer?.startDateTime)
                        ? dateConversionIsoToLocal(
                            selectedOffer?.startDateTime,
                            FORMAT_TYPE?.DATE_TIME,
                            '',
                            tenantConfig?.locale,
                          )
                        : selectedOffer?.startDateTime || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Offer End Date:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {isValidISO8601(selectedOffer?.endDateTime)
                        ? dateConversionIsoToLocal(
                            selectedOffer?.endDateTime,
                            FORMAT_TYPE?.DATE_TIME,
                            '',
                            tenantConfig?.locale,
                          )
                        : selectedOffer?.endDateTime || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Type:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'instantwin'
                        ? 'INSTANT_WIN'
                        : selectedTransaction?.offerType?.toUpperCase()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Channel:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {getRedemptionMethod(selectedOffer?.redemptionDetails)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Merchant:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedOffer?.merchant?.name || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Status:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'instantwin'
                        ? getStatusText(selectedTransaction?.status)
                        : selectedTransaction?.status || '-'}
                    </Typography>
                  </Box>
                </Grid>
                {selectedTransaction?.offerType?.toLowerCase()?.trim() !== 'cashback' &&
                  selectedTransaction?.offerType?.toLowerCase()?.trim() !== 'travel' &&
                  selectedTransaction?.offerType?.toLowerCase()?.trim() !== 'voucher' && (
                    <>
                      <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                        <Box
                          sx={{
                            borderBottom: '1px solid gray',
                            paddingBottom: '8px',
                            marginBottom: '8px',
                          }}>
                          <Typography
                            variant="body2"
                            sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                            User Claim Count:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                            {getAdditionalDetailValue(
                              selectedOffer?.additionalDetails,
                              'userClaimCount',
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                        <Box
                          sx={{
                            borderBottom: '1px solid gray',
                            paddingBottom: '8px',
                            marginBottom: '8px',
                          }}>
                          <Typography
                            variant="body2"
                            sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                            Claim Limit:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                            {getAdditionalDetailValue(
                              selectedOffer?.additionalDetails,
                              'claimLimit',
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'travel' && (
                  <>{renderFields(fieldsData.travelBookingDetails, selectedTransaction)}</>
                )}
              </Grid>
            </Box>
            {selectedOffer && (
              <TnCDialog
                toggleTnDDialog={toggleTnDDialog}
                isDialogVisible={isDialogVisible}
                transactionDetails={selectedOffer}
              />
            )}
          </Fragment>
        </Paper>
      )}
      {otherOfferTypes.includes(selectedTransaction?.offerType?.toLowerCase()?.trim()) &&
        (openSRDetailViewDialogDialog ? (
          <SRDetailViewDialog
            openSRDetailViewDialogDialog={openSRDetailViewDialogDialog}
            isOenSRDetailViewDialogDialog={isOenSRDetailViewDialogDialog}
            claimId={selectedTransaction?.id}
          />
        ) : (
          <Paper sx={styles.paper} elevation={3}>
            <BackButtonWText text={'Back to list'} callback={toggleView} />
            <Box sx={{padding: 3}}>
              {loading && <Typography variant="body2">Loading...</Typography>}
              <Typography
                variant="h5"
                sx={{
                  marginBottom: 2,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'inherit',
                }}>
                Transaction Details
              </Typography>
              {error && (
                <Typography variant="body2" sx={{color: 'red', padding: 2}}>
                  {error}
                </Typography>
              )}
              <Typography
                variant="h6"
                sx={{
                  margin: '10px 10px',
                  fontWeight: 'bold',
                  textAlign: 'Left',
                  fontFamily: 'inherit',
                }}>
                Booking Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      First Name:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedTransaction?.data?.claimDetails?.userDetails?.firstName || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Last Name:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedTransaction?.data?.claimDetails?.userDetails?.lastName || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Email:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedTransaction?.data?.claimDetails?.userDetails?.email || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                  <Box
                    sx={{
                      borderBottom: '1px solid gray',
                      paddingBottom: '8px',
                      marginBottom: '8px',
                    }}>
                    <Typography
                      variant="body2"
                      sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                      Phone No:
                    </Typography>
                    <Typography variant="body2" sx={{marginLeft: '8px', fontFamily: 'inherit'}}>
                      {selectedTransaction?.data?.claimDetails?.userDetails?.phoneNo || 'NA'}
                    </Typography>
                  </Box>
                </Grid>
                {renderFields(fieldsData.bookingDetails, selectedTransaction)}
              </Grid>
              {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'travel' && (
                <Grid container spacing={2}>
                  {renderFields(fieldsData.travelBookingDetails, selectedTransaction)}
                </Grid>
              )}
              <Divider sx={{my: 2}} />
              {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'event' ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      margin: '10px 10px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'inherit',
                    }}>
                    Event Details
                  </Typography>
                  <Grid container spacing={3}>
                    {renderFields(fieldsData.eventDetails, selectedTransaction)}
                    {renderCashbackDetails()}
                  </Grid>
                  <Divider sx={{my: 2}} />
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      margin: '10px 10px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'inherit',
                    }}>
                    Event Details
                  </Typography>
                  <Grid container spacing={3}>
                    {renderFields(fieldsData.cinemaEventDetails, selectedTransaction)}
                    <Grid item sx={{paddingLeft: '10px', paddingRight: '10px'}} xs={12} md={4}>
                      <Box
                        sx={{
                          borderBottom: '1px solid gray',
                          paddingBottom: '8px',
                          marginBottom: '8px',
                        }}>
                        <Typography
                          variant="body2"
                          sx={{fontWeight: 'bold', display: 'inline', fontFamily: 'inherit'}}>
                          Currency:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{marginLeft: '8px', fontWeight: 'normal', fontFamily: 'inherit'}}>
                          {getCurrency(selectedMember)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider sx={{my: 2}} />
                </>
              )}
              {selectedTransaction?.offerType?.toLowerCase()?.trim() === 'event' ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      margin: '10px 10px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'inherit',
                    }}>
                    Ticket Details
                  </Typography>
                  {renderEventTicketDetails(
                    selectedTransaction?.data?.claimDetails?.orderData?.data?.seatInfo ?? [],
                  )}
                  <Divider sx={{my: 2}} />
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      margin: '10px 10px',
                      fontWeight: 'bold',
                      textAlign: 'left',
                      fontFamily: 'inherit',
                    }}>
                    Ticket Details
                  </Typography>
                  {renderCinemaTicketDetails(
                    selectedTransaction?.data?.claimDetails?.orderData?.data?.seatInfo ?? [],
                  )}
                  <Divider sx={{my: 2}} />
                </>
              )}
              <Typography
                variant="h6"
                sx={{
                  margin: '10px 10px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontFamily: 'inherit',
                }}>
                Payment Details
              </Typography>
              {paymentDetailsRequests?.length > 0 ? (
                <TableView
                  {...getPaymentData(
                    {},
                    paymentDetailsRequests,
                    SortOrder.ASCENDING,
                    'assetType',
                    false,
                  )}></TableView>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{color: 'gray', textAlign: 'center'}}>
                    No payment details available.
                  </Typography>
                </Grid>
              )}

              {/* {renderPaymentDetails()} */}
              {/* Service Request Table */}
              {/* {renderServiceRequestTable()}  */}
            </Box>

            <Typography
              variant="h6"
              sx={{
                margin: '10px 10px',
                fontWeight: 'bold',
                textAlign: 'left',
                fontFamily: 'inherit',
              }}>
              Linked SR
            </Typography>
            {serviceRequests?.length ? (
              <SRListView handleSRRowClick={handleSRRowClick} SRListData={serviceRequests} />
            ) : (
              <Grid item xs={12}>
                <Typography variant="body2" sx={{color: 'gray', textAlign: 'center'}}>
                  No Linked SR's
                </Typography>
              </Grid>
            )}

            <Button
              onClick={handleNewSRClick}
              variant="contained"
              sx={{
                paddingY: globalSpacing.xxs,
                marginY: globalSpacing.sm,
              }}>
              <Typography variant="body2" sx={{textTransform: 'none'}}>
                {'New SR'}
              </Typography>
            </Button>
          </Paper>
        ))}
    </>
  );
};

export default TransactionDetails;
