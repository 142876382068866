import {SR_TABS, CDP_ALLOWED_TENANT_ID} from 'Utils/constants/genericConstants';
import {SRPageSize} from 'Utils/enums';
import {getCurrentSubscription} from './MemberDetails';
export const searchSRByTypePayload = (memberDetails, type, loginDetails) => {
  console.log(loginDetails);
  //debugger;
  let payload = {
    searchServiceRequestByTypeInput: {
      memberId: memberDetails?.currentMemberDetails?.memberId,
      membershipId: memberDetails?.membershipId,
      ...(CDP_ALLOWED_TENANT_ID.includes(String(loginDetails?.tenantID)) && {
        userId: memberDetails?.currentMemberDetails?.userId,
      }), // Add userId conditionally
      ownerId: '',
      type: type,
      subType: '',
      status: [],
      createdBy: '',
      membershipSubscriptionDate: getCurrentSubscription(memberDetails)?.startDate || null,
      paging: {
        pageNumber: 0,
        pageSize: SRPageSize[type.toUpperCase()],
        orderBy: 'id',
        sortOrder: 'descending',
      },
      dateRange: {
        startDate: getCurrentSubscription(memberDetails)?.startDate || null,
        endDate: getCurrentSubscription(memberDetails)?.endDate || null,
      },
    },
  };
  if (type === SR_TABS.ARCHIVED) {
    payload.searchServiceRequestByTypeInput.dateRange = {
      startDate: null,
      endDate: null,
    };
  }

  return payload;
};
